import React, { useEffect, useState } from "react";
import Backend from "../BackendProxy";

const UserMessagesList = ({ customerId }) => {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    Backend.request("GET", "profile/" + customerId)
      .then((result) => result.json())
      .then((data) => {
        Backend.request("GET", "notification/?userId=" + data.userId)
          .then((result) => result.json())
          .then((data) => {
            setCustomers(data);
            setLoading(false);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, [customerId]);

  const dateFormatOptions = {
    hour12: false,
    timeZone: "Europe/Berlin",
    dateStyle: "medium",
    timeStyle: "medium",
  };

  const truncate = (input, length = 50) => {
    if (input.length > length) {
      return input.substring(0, length) + "...";
    }
    return input;
  };

  const rows = customers
    .sort((a, b) => {
      return a.createdAt < b.createdAt ? 1 : -1;
    })
    .map((c) => {
      let status = 0;
      if (c.deletedAt) {
        status = 3;
      } else if (c.readAt) {
        status = 2;
      } else if (c.readAt == null) {
        status = 1;
      }
      return { ...c, status };
    })
    .map((c) => (
      <tr>
        <td className="px-6 py-4 whitespace-no-wrap">
          {c.status === 0 ? (
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-gray-100 bg-gray-400 rounded-full">
              Nicht zugestellt
            </span>
          ) : null}
          {c.status === 1 ? (
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
              Ungelesen
            </span>
          ) : null}
          {c.status === 2 ? (
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-green-100 bg-green-600 rounded-full">
              Gelesen
            </span>
          ) : null}
          {c.status === 3 ? (
            <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-black bg-yellow-300 rounded-full">
              Gelöscht
            </span>
          ) : null}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap">
          {new Intl.DateTimeFormat("de", dateFormatOptions).format(
            new Date(c.createdAt * 1000)
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap">
          <span className="font-bold">{truncate(c.title)}</span>
          <br />
          <span className="text-gray-600 text-sm">{truncate(c.body, 150)}</span>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap">{c.url}</td>
      </tr>
    ));

  let content = loading ? (
    <div>Nachrichten laden...</div>
  ) : (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Zeitpunkt
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Inhalt
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Landing-URL
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default UserMessagesList;
