import React, { useEffect, useState } from "react";
import Map from "./Map";
import Backend from "../BackendProxy";

const CustomerPositionTable = ({ customerId }) => {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);

  let token = JSON.parse(
    Buffer.from(localStorage.getItem("token").split(".")[1], "base64")
  );

  useEffect(() => {
    Backend.request("GET", "profile/" + customerId)
      .then((result) => result.json())
      .then((data) => {
        Backend.request("GET", "positions/" + data.userId)
          .then((result) => result.json())
          .then((data) => {
            setCustomers(data);
            setLoading(false);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }, [customerId]);

  const rows = customers.map((c) => (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap">
        {new Date(c.timestamp * 1000).toLocaleDateString("de-DE")}&nbsp;
        {new Date(c.timestamp * 1000).toLocaleTimeString("de-DE")}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap">{c.latitude}</td>
      <td className="px-6 py-4 whitespace-no-wrap">{c.longitude}</td>
      <td className="px-6 py-4 whitespace-no-wrap">
        <a
          href={
            "https://www.google.com/maps/search/?api=1&query=" +
            c.latitude +
            "," +
            c.longitude
          }
          target="_blank"
          rel="noreferrer"
        >
          Maps öffnen
        </a>
      </td>
    </tr>
  ));

  if (!token.scope.split(" ").includes("ADMIN")) {
    return <div>No acces rights</div>;
  }

  let content = loading ? (
    <div>Kartendaten laden...</div>
  ) : (
    <div className="flex flex-col">
      <Map markers={customers} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Zeitpunkt
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Latitude
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Longtitude
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default CustomerPositionTable;
