import React, { useEffect, useState } from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import Backend from "../BackendProxy";
import { useParams } from "react-router-dom";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import Toast from "../ToastProxy";
import CustomerCounter from "../components/CustomerCounter";
import CampaignCustomerTable from "../components/CampaignCustomerTable"
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const allowOnlyNumbers = (evt) => {
  if (
    !evt.key.match(/[0-9.,]/) &&
    evt.key !== "Backspace" &&
    evt.key !== "ArrowLeft" &&
    evt.key !== "ArrowRight" &&
    evt.key !== "Delete" &&
    evt.key !== "Tab"
  ) {
    evt.preventDefault();
  }
};

const Dropdown = ({ values = [], onChange = (v) => { }, value }) => {
  const options = values.map((v) => (
    <option value={v.value} key={v.value}>
      {v.name}
    </option>
  ));
  return (
    <div className="relative inline-flex">
      <svg
        className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 412 232"
      >
        <path
          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
          fill="#648299"
          fillRule="nonzero"
        />
      </svg>
      <select
        className="border border-gray-300 rounded-md text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
        onChange={(v) => onChange(v.target.value)}
        value={value}
      >
        {options}
      </select>
    </div>
  );
};

const platforms = [
  {
    name: "Alle",
    value: "",
  },
  {
    name: "Android",
    value: "ANDROID",
  },
  {
    name: "iOS",
    value: "IOS",
  },
];

const activateStates = [
  {
    name: "Alle",
    value: "both",
  },
  {
    name: "Aktive",
    value: "true",
  },
  {
    name: "Inaktive",
    value: "false",
  },
];

const customerTypes = [
  {
    name: "Alle",
    value: "",
  },
  {
    name: "Firmenkunde",
    value: "BUSINESS",
  },
  {
    name: "Privatkunde",
    value: "PRIVATE",
  },
];

const CustomerCampaignPage = ({ routes }) => {
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [appUserCount, setAppUserCount] = useState(0);
  const [branchFilter, setBranchFilter] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isActive, setIsActive] = useState("");
  const [platform, setPlatform] = useState("");
  const [minEstimatedPriceFilter, setMinEstimatedPriceFilter] = useState("");
  const [maxEstimatedPriceFilter, setMaxEstimatedPriceFilter] = useState("");
  const [customerTypeFilter, setCustomerTypeFilter] = useState("");
  const [minCredit, setMinCredit] = useState("");
  const [maxCredit, setMaxCredit] = useState("");
  const [minCountContracts, setMinCountContracts] = useState("");
  const [maxCountContracts, setMaxCountContracts] = useState("");
  const [minBonusPoints, setMinBonusPoints] = useState("");
  const [maxBonusPoints, setMaxBonusPoints] = useState("");
  const [campaign, setCampaign] = useState({})

  const { campaignId } = useParams();

  const _stringToNumericString = (number) => {
    return number.replaceAll(',','.');
  }

  const prepareFilterRequestList = () => {
    var filters = [];
    if (branchFilter.length > 0) {
      filters.push({
        column: "ProfileBranchFilter",
        value: branchFilter.map(v => v.value).join(","),
      });
    }
    if (isActive === "true") {
      filters.push({ column: "ActiveProfilesFilter" });
    }
    if (isActive === "false") {
      filters.push({ column: "InactiveProfilesFilter" });
    }
    if (platform !== "") {
      filters.push({
        column: "ProfilesByPlatforms",
        value: platform,
      });
    }
    if (minCredit !== "" || maxCredit !== "") {
      let min = _stringToNumericString(minCredit);
      let max = _stringToNumericString(maxCredit);

      if (min === "") {
        min = 0;
      }

      if (max === "") {
        max = 999999999;
      }

      filters.push({
        column: "ProfilesLastCreditFilter",
        value: `${min},${max}`,
      });
    }
    if (customerTypeFilter !== "") {
      filters.push({
        column: "ProfilesTypeFilter",
        value: customerTypeFilter,
      });
    }
    if (minEstimatedPriceFilter !== "" || maxEstimatedPriceFilter !== "") {
      let min = _stringToNumericString(minEstimatedPriceFilter);
      let max = _stringToNumericString(maxEstimatedPriceFilter);

      if (min === "") {
        min = 0;
      }

      if (max === "") {
        max = 999999999;
      }

      filters.push({
        column: "ProfilesContractEstimatedValueFilter",
        value: `${min},${max}`,
      });
    }
    if (minCountContracts !== "" || maxCountContracts !== "") {
      let min = _stringToNumericString(minCountContracts);
      let max = _stringToNumericString(maxCountContracts);

      if (min === "") {
        min = 0;
      }

      if (max === "") {
        max = 999999999;
      }

      filters.push({
        column: "ProfilesContractCountFilter",
        value: `${min},${max}`,
      });
    }
    if (minBonusPoints !== "" || maxBonusPoints !== "") {
      let min = _stringToNumericString(minBonusPoints);
      let max = _stringToNumericString(maxBonusPoints);

      if (min === "") {
        min = 0;
      }

      if (max === "") {
        max = 999999999;
      }

      /*filters.push({
        column: "ProfileBonuspointsFilter",
        value: `${min},${max}`,
      });*/
    }
    return filters;
  };

  const saveFilters = () => {
    Backend.request(
      "DELETE",
      "marketing/campaign/" + campaignId + "/filters",
      null
    ).then((result) => {
      if (result.status === 200) {
        var filters = prepareFilterRequestList();

        Backend.request(
          "POST",
          "marketing/campaign/" + campaignId + "/filters",
          JSON.stringify({
            campaignId: campaignId,
            filters: filters,
          })
        )
          .then((result) =>
            Toast.success(
              "Filter speichern",
              "Die Filter wurden erfolgreich gespeichert"
            )
          )
          .catch(() => {
            Toast.error(
              "Filter speichern",
              "Die Filter konnten nicht gespeichert werden"
            );
          });
      }
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const searchCustomers = (() => {
    setLoading(true);
    Backend.request(
      "POST",
      "marketing/campaign/" + campaignId + "/customerCountWithFilter",
      JSON.stringify({
        campaignId: campaignId,
        filters: prepareFilterRequestList(),
      })
    )
      .then((result) => result.json())
      .then((result) => {
        setUserCount(parseInt(result.customers));
        setAppUserCount(parseInt(result.users));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  });
  /* eslint-enable */

  useEffect(() => {
    const branchesRequest = Backend.request("GET", "branches")
      .then((result) => result.json());

    const filtersRequest = Backend.request("GET", "marketing/campaign/" + campaignId + "/filters")
      .then((result) => result.json());


    const campaignRequest = Backend.request("GET", "marketing/campaign/" + campaignId)
    .then((result) => result.json());

    Promise.all([branchesRequest, filtersRequest, campaignRequest]).then(([branches, filters, campaign]) => {
      setCampaign(campaign);
      const sortedBranches = branches.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      ).map((b) => {
        return { label: b.name, value: b.id };
      });
      sortedBranches.push({ label: "Alle Filialen", id: 0 });
      setBranches(sortedBranches);

      filters.forEach((value) => {
        switch (value.column) {
          case "ActiveProfilesFilter":
            setIsActive("true");
            break;
          case "InactiveProfilesFilter":
            setIsActive("false");
            break;
          case "ProfileBranchFilter":
            const branchIds = value.value.split(/,/).map(v => parseInt(v));
            setBranchFilter(sortedBranches.filter(b => branchIds.includes(b.value)));
            break;
          case "ProfilesByPlatforms":
            setPlatform(value.value);
            break;
          case "ProfilesTypeFilter":
            setCustomerTypeFilter(value.value);
            break;
          case "ProfilesLastCreditFilter":
            const [min, max] = value.value.split(/,/);
            setMinCredit(min);
            setMaxCredit(max);
            break;
          case "ProfilesContractEstimatedValueFilter":
            const [minEstimated, maxEstimated] = value.value.split(/,/);
            setMinEstimatedPriceFilter(minEstimated);
            setMaxEstimatedPriceFilter(maxEstimated);
            break;
          case "ProfilesContractCountFilter":
            const [minCount, maxCount] = value.value.split(/,/);
            setMinCountContracts(minCount);
            setMaxCountContracts(maxCount);
            break;
          case "ProfileBonuspointsFilter":
            const [minPoints, maxPoints] = value.value.split(/,/);
            setMinBonusPoints(minPoints);
            setMaxBonusPoints(maxPoints);
            break;
          default:
            break;
        }
      });
    }).catch((error) => console.log(error));

  }, [campaignId]);

  return (
    <ApplicationFrame
      title={campaign.name ?? 'Marketing'}
      subtitle="Kundenauswahl für Marketingaktion"
      routes={routes}
    >
      <div className="p-6 mt-5 md:mt-0 md:col-span-2">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-2 bg-white sm:p-6">
            <h3 className="text-lg font-bold text-gray-600">
              Filter für die Auswahl der Kunden
            </h3>
            <div className="w-full flex flex-col">
              <div className="flex mr-3 items-center mt-2 justify-between p-1">
                <p className="text-gray-600 text-sm uppercase mr-3">Filiale</p>
                <Select
                  options={branches}
                  isMulti
                  value={branchFilter}
                  onChange={(v) => {
                    setBranchFilter(v)
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      minWidth: 200,
                    }),
                  }}
                  placeholder={"Alle Filialen"}
                />
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Aktiver Kunde
                </p>
                <Dropdown
                  values={activateStates}
                  onChange={(value) => setIsActive(value)}
                  value={isActive}
                />
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Plattform
                </p>
                <Dropdown
                  values={platforms}
                  onChange={(value) => setPlatform(value)}
                  value={platform}
                />
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Kundenart
                </p>
                <Dropdown
                  values={customerTypes}
                  onChange={(value) => setCustomerTypeFilter(value)}
                  value={customerTypeFilter}
                />
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Letzte Auszahlung zwischen
                </p>
                <div className="flex items-center">
                  <div className="flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder={"10.000,00"}
                      type="text"
                      value={minCredit}
                      onKeyDown={allowOnlyNumbers}
                      onChange={(evt) => setMinCredit(evt.target.value)}
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      <FontAwesomeIcon icon={faEuroSign} />
                    </span>
                  </div>
                  <span className="text-gray-600 text-sm uppercase mx-3">
                    und
                  </span>
                  <div className="flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder={"10.000,00"}
                      type="text"
                      value={maxCredit}
                      onKeyDown={allowOnlyNumbers}
                      onChange={(evt) => setMaxCredit(evt.target.value)}
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      <FontAwesomeIcon icon={faEuroSign} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Schätzwert des letzten Vertrags zwischen
                </p>
                <div className="flex items-center">
                  <div className="flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder={"10.000,00"}
                      type="text"
                      value={minEstimatedPriceFilter}
                      onKeyDown={allowOnlyNumbers}
                      onChange={(evt) => setMinEstimatedPriceFilter(evt.target.value)}
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      <FontAwesomeIcon icon={faEuroSign} />
                    </span>
                  </div>
                  <span className="text-gray-600 text-sm uppercase mx-3">
                    und
                  </span>
                  <div className="flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder={"10.000,00"}
                      type="text"
                      value={maxEstimatedPriceFilter}
                      onKeyDown={allowOnlyNumbers}
                      onChange={(evt) => setMaxEstimatedPriceFilter(evt.target.value)}
                    />
                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      <FontAwesomeIcon icon={faEuroSign} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex mr-3 items-center mt-2 justify-between p-1">
                <p className="text-gray-600 text-sm uppercase mr-3">
                  Anzahl Verträge zwischen
                </p>
                <div className="flex items-center">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder=""
                      type="text"
                      value={minCountContracts}
                      onChange={(e) => setMinCountContracts(e.target.value)}
                    />
                  </div>
                  <span className="text-gray-600 text-sm uppercase mx-3">
                    und
                  </span>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                      spellCheck="false"
                      placeholder=""
                      type="text"
                      value={maxCountContracts}
                      onChange={(e) => setMaxCountContracts(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/*<div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">*/}
              {/*  <p className="text-gray-600 text-sm uppercase mr-3">*/}
              {/*    Anzahl Bonuspunkte zwischen*/}
              {/*  </p>*/}
              {/*  <div className="flex items-center">*/}
              {/*    <div className="mt-1 flex rounded-md shadow-sm">*/}
              {/*      <input*/}
              {/*        className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"*/}
              {/*        spellCheck="false"*/}
              {/*        placeholder=""*/}
              {/*        type="text"*/}
              {/*        value={minBonusPoints}*/}
              {/*        onChange={(e) => setMinBonusPoints(e.target.value)}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <span className="text-gray-600 text-sm uppercase mx-3">*/}
              {/*      und*/}
              {/*    </span>*/}
              {/*    <div className="mt-1 flex rounded-md shadow-sm">*/}
              {/*      <input*/}
              {/*        className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"*/}
              {/*        spellCheck="false"*/}
              {/*        placeholder=""*/}
              {/*        type="text"*/}
              {/*        value={maxBonusPoints}*/}
              {/*        onChange={(e) => setMaxBonusPoints(e.target.value)}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-between">
            <CustomerCounter
              title={"Kunden"}
              value={userCount}
              max={userCount}
              loading={loading}
            />
            <CustomerCounter
              title={"Kunden mit App"}
              value={appUserCount}
              max={appUserCount}
              loading={loading}
            />
            <button
              type="button"
              onClick={searchCustomers}
              className="h-10 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Erreichbare Kunden ermitteln
            </button>
            <button
              type="button"
              onClick={saveFilters}
              className="h-10 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Filter speichern
            </button>
          </div>
        </div>
        <CampaignCustomerTable campaignId={campaignId} />
      </div>
    </ApplicationFrame>
  );
};

export default CustomerCampaignPage;
