import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
  key,
  path,
  exact,
  children,
  neededGroups = ["ADMIN"],
}) => {
  let token = localStorage.getItem("token");
  let isAuthenticated = false;
  let hasRights = false;
  if (token) {
    token = token.split(".");
    let jwt = JSON.parse(Buffer.from(token[1], "base64"));
    let groups = jwt.scope.split(" ");
    isAuthenticated = jwt.exp > Math.floor(Date.now() / 1000);
    hasRights = neededGroups.filter((val) => groups.includes(val)).length > 0;
  }

  return isAuthenticated ? (
    hasRights ? (
      <Route key={key} path={path} exact={exact} children={children} />
    ) : (
      <div className="text-red-600 text-center pt-6">
        Sie haben nicht die benötigten Rechte um diese Seite aufzurufen, bitte
        kontaktieren Sie einen Administrator.
      </div>
    )
  ) : (
    <Redirect to="/login" />
  );
};

export default ProtectedRoute;
