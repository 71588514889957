import type {ChangeEvent, Dispatch, FormEventHandler, ReactNode, SetStateAction} from "react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faSearch, faSpinner, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

type StateProp<T> = [T, Dispatch<SetStateAction<T>>];

type Column<T> = {
    name: string,
    filterable: true,
    dataName: keyof T,
    data?: (value: T) => ReactNode,
    possibleValues?: string[],
} | {
    name: string,
    filterable: false,
    data: (value: T) => ReactNode,
};

interface Page<T> {
    total: number,
    items: Array<T>,
}

export type FilterValues<T> = { [key in keyof T]?: string }

export type SearchTableLoader<T> = (page: number, perPage: number, filter: FilterValues<T>) => Promise<Page<T>>;

export type SearchTableColumns<T> = Array<Column<T>>;

interface SearchTableProps<T> {
    loader: SearchTableLoader<T>,
    columns: Array<Column<T>>,
    rowId: (row: T) => string | number,
    localStoreKey?: string,
    onSelectionChange?: (row: T, selected: boolean) => void,
    reloadOnFilterKeys?: Array<keyof T>,
    reloadOnChange?: any[],
}

export default function SearchTable<T>(
    {
        loader,
        columns,
        rowId,
        localStoreKey,
        onSelectionChange,
        reloadOnChange,
        reloadOnFilterKeys,
    }: SearchTableProps<T>
) {
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState<Page<T>>({total: 0, items: []});
    const [forceReload, setForceReload] = useState(false);
    const [filters, setFilters] = useState<FilterValues<T>>(() => {
        //TODO Load filter-state from localStorage
        return {};
    });

    const reloadPage = () => {
        setLoading(true);
        loader(page, perPage, filters)
            .then(setContent)
            .catch(console.error)
            .finally(() => {
                setLoading(false);
                //TODO Save filter-state to localStorage
            });
    };

    const resetAndReloadPage = () => {
        setPage(0);
        reloadPage();
    };

    const clearFilters = () => {
        setFilters(filters => Object.keys(filters).reduce((result, key) => ({...result, [key]: ""}), {}));
        setForceReload(true);
    };

    // Load after mount, reload when changing page or size
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(reloadPage, []);
    useOnChange(reloadPage, [page]);
    useOnChange(resetAndReloadPage, [perPage]);

    // Listen to the force-reload flag
    useEffect(() => {
        if (forceReload) {
            setForceReload(false);
            resetAndReloadPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReload]);

    // Listen to other changes
    useOnChange(resetAndReloadPage, [
        ...(reloadOnChange ?? []),
        ...(reloadOnFilterKeys ? reloadOnFilterKeys.map(key => filters[key]) : [])
    ]);

    return <div className="flex flex-col">
        <div className="my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                        <tr>
                            {/* Selection column (with loading indicator) */}
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                {loading ? <>
                                    <FA spin icon={faSpinner} size={"2x"}/>
                                </> : <>
                                    <button type="button" onClick={resetAndReloadPage}
                                            className="px-2 py-1 active:text-blue-700">
                                        <FA icon={faSearch} size={"2x"}/>
                                    </button>
                                    <button type="button" onClick={clearFilters}
                                            className="px-2 py-1 active:text-red-600">
                                        <FA icon={faTrashAlt} size={"2x"}/>
                                    </button>
                                </>}
                            </th>
                            {/* Data columns */}
                            {columns.map(column => <ColumnHeader
                                key={column.name as string | number}
                                column={column}
                                filters={[filters, setFilters]}
                                onSubmit={reloadPage}
                            />)}
                        </tr>
                        </thead>
                        <tbody>
                        {content.items.map(row => {
                            const id = rowId(row);
                            return <DataRow
                                key={id}
                                row={row}
                                columns={columns}
                                onSelectionChange={onSelectionChange}
                            />
                        })}
                        </tbody>
                    </table>
                    <Footer
                        loading={loading}
                        count={content.total}
                        page={[page, setPage]}
                        perPage={[perPage, setPerPage]}
                    />
                </div>
            </div>
        </div>
    </div>;
}

interface ColumnHeaderProps<T> {
    column: Column<T>,
    filters: StateProp<FilterValues<T>>,
    onSubmit: () => void,
}

function ColumnHeader<T>(
    {
        column,
        onSubmit,
        filters: [filters, setFilters],
    }: ColumnHeaderProps<T>
) {
    const formRef = useRef<HTMLFormElement>(undefined!);

    const filterValueChanged = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const filterValue = e.currentTarget.value;
        if (column.filterable) {
            const dataName = column.dataName;
            setFilters(filters => ({...filters, [dataName]: filterValue}));
        }
    }, [column, setFilters]);

    return <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
        <form ref={formRef} onSubmit={preventDefault(onSubmit)}>
            <div>{column.name}</div>
            <div>{column.filterable ? (
                (column.possibleValues && (column.possibleValues.length > 0)) ? (
                    <select value={filters[column.dataName] || ""}
                            onChange={filterValueChanged}
                            className="border p-2 rounded bg-white">
                        <option value={""}>{/* Empty value */}</option>
                        {column.possibleValues.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                ) : (
                    <input value={filters[column.dataName] || ""}
                           onChange={filterValueChanged}
                           className="border p-2 rounded"/>
                )
            ) : (
                <div style={{height: "34px"}}>&nbsp;</div>
            )}</div>
        </form>
    </th>;
}

interface DataRowProps<T> {
    row: T,
    columns: Array<Column<T>>,
    onSelectionChange: undefined | ((row: T, selected: boolean) => void)
}

function DataRow<T>(
    {
        row,
        columns,
        onSelectionChange,
    }: DataRowProps<T>
) {
    const [selected, setSelected] = useState(false);

    const updateSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const selected = e.currentTarget.checked;
        onSelectionChange?.(row, selected);
        setSelected(selected);
    }, [row, onSelectionChange]);

    return <tr className="bg-white">
        {/* Selection column */}
        <td className="pl-4 py-4 whitespace-no-wrap">
            {onSelectionChange && (
                <label className="inline-flex items-start mt-3">
                    <input
                        type="checkbox"
                        checked={selected}
                        onChange={updateSelected}
                        className="form-checkbox h-5 w-5 text-green-600"
                    />
                </label>
            )}
        </td>
        {/* Data columns */}
        {columns.map(column => <DataColumn key={column.name} row={row} column={column}/>)}
    </tr>;
}

function DataColumn<T>({column, row}: { column: Column<T>, row: T }) {
    const value = column.filterable
        ? (column.data?.(row) || row[column.dataName] || "")
        : (column.data(row) || "");

    return <td className="px-6 py-4 whitespace-no-wrap">
        <div className="flex items-center">
            <div className="text-sm leading-5 font-medium text-gray-900">
                {value}
            </div>
        </div>
    </td>;
}

interface FooterProps {
    loading: boolean,
    count: number,
    page: StateProp<number>,
    perPage: StateProp<number>,
}

function Footer({loading, count, page: [page, setPage], perPage: [perPage, setPerPage]}: FooterProps) {
    const [pageInputValue, setPageInputValue] = useState(page + 1);
    const totalPages = Math.floor(count / perPage) + 1;

    // Bind page-input to current page prop from parent
    useEffect(() => setPageInputValue(page + 1), [page]);

    return <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex items-center">
                {loading ? (
                    <div className="text-gray-700 mr-2">
                        <FA spin icon={faSpinner}/> Lade Seitendaten
                    </div>
                ) : (
                    <p className="text-sm leading-5 text-gray-700">
                        Zeige&nbsp;
                        <span className="font-medium">{count}</span>
                        &nbsp;Ergebnissen&nbsp;
                        <span className="font-medium ml-4">Seitengröße:</span>
                        <select
                            value={perPage}
                            onChange={(evt) => setPerPage(parseInt(evt.target.value))}
                            className="font-medium p-1 ml-2 rounded">
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </p>
                )}
            </div>
            <div>
                <nav className="relative z-0 inline-flex shadow-sm">
                    <button aria-label="Previous"
                            onClick={() => setPage(page => page - 1)}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                        <svg className="h-5 w-5"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"/>
                        </svg>
                    </button>
                    <input className="form-input text-right w-20 border-0"
                           placeholder="Seite"
                           value={pageInputValue}
                           onChange={(evt) => setPageInputValue(parseInt(evt.target.value))}
                           onKeyUp={(evt) => (evt.code === "Enter") && setPage(pageInputValue - 1)}/>
                    <span className="pt-2 pr-2">&nbsp;von {totalPages}</span>
                    <button aria-label="Next"
                            onClick={() => setPage(page => page + 1)}
                            className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150">
                        <svg className="h-5 w-5"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"/>
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>;
}

type PreventDefault = <E = Element>(andThen: FormEventHandler<E>) => FormEventHandler<E>;
const preventDefault: PreventDefault = andThen => e => {
    e.preventDefault();
    andThen(e);
};

const useOnChange = (callback: () => void, onChange: readonly any[]) => {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) {
            callback();
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, onChange);
};
