import React, { useEffect, useState } from "react";
import L from "leaflet";
// postCSS import of Leaflet's CSS
import "leaflet/dist/leaflet.css";

// store the map configuration properties in an object,
// we could also move this to a separate file & import it if desired.
let config = {};
config.params = {
  center: [50.5, 8.09],
  zoomControl: false,
  zoom: 9,
  maxZoom: 18,
  minZoom: 11,
  scrollwheel: false,
  legends: true,
  infoControl: false,
  attributionControl: true,
};
config.tileLayer = {
  uri: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  params: {
    minZoom: 11,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
    id: "",
    accessToken: "",
  },
};

const Map = ({ markers }) => {
  const [mapRef, setMapRef] = useState(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (mapRef != null && map == null) {
      let m = L.map(mapRef, config.params);
      L.control.zoom({ position: "bottomleft" }).addTo(m);
      L.control.scale({ position: "bottomleft" }).addTo(m);

      // a TileLayer is used as the "basemap"
      L.tileLayer(config.tileLayer.uri, config.tileLayer.params).addTo(m);
      const sortedMarkers = markers.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      sortedMarkers.forEach((marker) =>
        L.circle([marker.latitude, marker.longitude], {
          color: "red",
          fillColor: "#f03",
          fillOpacity: 0.5,
          radius: 100,
        })
          .addTo(m)
          .bindTooltip(
            new Date(marker.timestamp * 1000).toLocaleString() +
              "<br/>Timestamp: " +
              marker.timestamp
          )
      );
      if (sortedMarkers.length > 0) {
        m.setView([sortedMarkers[0].latitude, sortedMarkers[0].longitude]);
      }
      setMap(m);
    }
  }, [mapRef, markers, map]);

  return (
    <div id="mapUI">
      <div ref={(node) => setMapRef(node)} id="map" />
    </div>
  );
};

export default Map;
