import React from "react";
import Navbar from "./Navbar";

export interface ApplicationFrameProps {
  title: string,
  subtitle: string,
  routes: any,
  children: React.ReactNode,
  back?: null | (() => void)
} 

const ApplicationFrame = ({ title, subtitle, routes, children, back = () => window.history.back() }: ApplicationFrameProps) => {
  const backButton = back ? <button onClick={() => back()} className="mr-4 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">Zurück</button> : <></>
  return (
    <>
      <Navbar title={title} routes={routes} />
      <div className="hidden bg-blue-dark md:block md:bg-white md:border-b">
        <div className="mx-auto px-6 flex items-center">
          {backButton}
          <div className="flex flex-col pt-2 pb-2">
            <h1 className="text-2xl text-gray-700 leading-tight">{title}</h1>
            <p className="text-base text-gray-700 leading-normal font-light">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col align-middle min-h-full">
        <div className="bg-gray-200 min-h-full">{children}</div>
      </div>
    </>
  );
};

export default ApplicationFrame;
