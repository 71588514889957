import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerCounter = ({ title, value, max, loading }) => {
  let content = <></>;
  if (loading) {
    content = <FontAwesomeIcon icon={faSpinner} spin={true} size="2x" />;
  } else {
    content = (
      <div className="text-xl font-bold">
        {new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
        }).format(value)}
      </div>
    );
  }
  return (
    <div className="flex flex-col bg-green">
      <div className=" text-xs uppercase font-normal">{title}</div>
      {content}
    </div>
  );
};

export default CustomerCounter;
