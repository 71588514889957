const backend = localStorage.getItem("backendUrl") || (window.location.host === 'web.pfando-app.de' ? "https://api.pfando-app.de/" : "https://api-test.pfando-app.de/");

const request = (method, endpoint, body = "") => {
  return fetch(backend + endpoint, {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("token"),
    },
    body: method === "GET" ? null : body,
  });
};

const generateUrl = (endpoint) => {
  return backend + endpoint;
};

const Backend = { request, generateUrl };

export default Backend;
