import React, { useState } from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import { useParams } from "react-router-dom";
import Backend from "../BackendProxy";
import { useForm } from "react-hook-form";
import Toast from "../ToastProxy";

const NotificationsPage = ({ routes }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  let { customerId } = useParams();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    Backend.request(
      "POST",
      "notification",
      JSON.stringify({
        title: subject,
        body: message,
        filter: { name: "single", args: [customerId] },
        url: url,
      })
    ).then(() => {
      Toast.success(
        "Nachricht senden",
        "Die Nachricht wurde erfolgreich an den Kunden gesendet"
      );
      setSubject("");
      setMessage("");
      setUrl("");
    });
  };

  return (
    <ApplicationFrame
      title="Notifications"
      subtitle="Nachricht an App-Benutzer versenden"
      routes={routes}
    >
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Nachricht versenden
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                Konfigurieren Sie in dieser Eingabemaske die Nachricht, die Sie
                an eine Gruppe von App-Benutzern senden wollen.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company_website"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Titel der Nachricht
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="company_website"
                          name="companyWebsite"
                          ref={register({ required: true, maxLength: 100 })}
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="Willkommen lieber Kunde"
                        />
                      </div>
                      {errors.companyWebsite?.type === "required" && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie einen Titel ein.
                        </div>
                      )}
                      {errors.companyWebsite?.type === "maxLength" && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie einen Titel mit maximal 100 Zeichen
                          ein.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="about"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Nachricht
                    </label>
                    <div className="rounded-md shadow-sm">
                      <textarea
                        id="about"
                        onChange={(e) => setMessage(e.target.value)}
                        name="message"
                        ref={register({ required: true, maxLength: 400 })}
                        value={message}
                        rows="3"
                        className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        placeholder="Ihre Nachricht"
                      ></textarea>
                    </div>
                    {errors.message?.type === "required" && (
                      <div className="text-xs text-red-600">
                        Bitte geben Sie eine Nachricht ein.
                      </div>
                    )}
                    {errors.message?.type === "maxLength" && (
                      <div className="text-xs text-red-600">
                        Die Nachricht ist zu lang. Die Nachricht kann nur
                        maximal 400 Zeichen enthalten.
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-3 gap-6 mt-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="landingpage"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Landingpage
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="www.pfando.de/aktion"
                          name="landingpage"
                          ref={register({
                            required: true,
                          })}
                          id="landingpage"
                        />
                      </div>
                      {errors.landingpage && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie eine Landingpage ein.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1 mt-6">
                    <label
                      id="listbox-label"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Senden an
                    </label>
                    <div className="relative">
                      <span className="inline-block w-full rounded-md shadow-sm">
                        <button
                          type="button"
                          aria-haspopup="listbox"
                          aria-expanded="true"
                          aria-labelledby="listbox-label"
                          className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          <div className="flex items-center space-x-3">
                            <span className="block truncate">
                              Spezifischen Nutzer
                            </span>
                          </div>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <svg
                              className="h-5 w-5 text-gray-400"
                              viewBox="0 0 20 20"
                              fill="none"
                              stroke="currentColor"
                            >
                              <path
                                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </button>
                      </span>
                      <div className="absolute mt-1 w-full hidden rounded-md bg-white shadow-lg">
                        <ul
                          tabIndex="-1"
                          role="listbox"
                          className="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                        >
                          <li
                            id="listbox-item-0"
                            role="option"
                            aria-selected="false"
                            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                          >
                            <div className="flex items-center space-x-3">
                              <span className="font-normal block truncate">
                                Alle Nutzer im Raum Frankfurt
                              </span>
                            </div>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Nachricht senden
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ApplicationFrame>
  );
};

export default NotificationsPage;
