import React, { useState, useEffect } from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import Backend from "../BackendProxy";
import Toast from "../ToastProxy";
import Dropdown from "../components/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
  faPercent,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import MultiSelect from "../components/Multiselect";
import FormattedInput from "../components/FormattedInput";

const CustomerCounter = ({ value, loading }) => {
  const result = loading ? (
    <FontAwesomeIcon icon={faSpinner} spin={true} />
  ) : (
    new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: 0,
    }).format(value)
  );
  return (
    <div className="flex flex-col bg-green">
      <div className=" text-xs uppercase font-normal">Erreichbare Kunden</div>
      <div className="text-xl font-bold">{result}</div>
    </div>
  );
};

const NotificationsPage = ({ routes }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");

  const [branches, setBranches] = useState([]);
  const [notifiableUsers, setNotifiableUsers] = useState([]);

  const [branchFilter, setBranchFilter] = useState(0);
  const [minCredit, setMinCredit] = useState("");
  const [maxCredit, setMaxCredit] = useState("");
  const [minRate, setMinRate] = useState("");
  const [maxRate, setMaxRate] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [isActive, setIsActive] = useState("");
  const [platform, setPlatform] = useState("");
  const [minAmountFilter, setMinAmountFilter] = useState("");
  const [dueDaysFilter, setDueDaysFilter] = useState("");
  const [minDueInDaysFilter, setMinDueInDaysFilter] = useState("");
  const [maxDueInDaysFilter, setMaxDueInDaysFilter] = useState("");
  const [customerTypeFilter, setCustomerTypeFilter] = useState("");
  const [minEstimatedPriceFilter, setMinEstimatedPriceFilter] = useState("");
  const [maxEstimatedPriceFilter, setMaxEstimatedPriceFilter] = useState("");
  const [maxRemainingDurationFilter, setMaxRemainingDurationFilter] = useState(
    ""
  );
  const [minExpiredDurationFilter, setMinExpiredDurationFilter] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState("ALL");
  const [loading, setLoading] = useState(true);

  const platforms = [
    {
      name: "Alle",
      value: "",
    },
    {
      name: "Android",
      value: "ANDROID",
    },
    {
      name: "iOS",
      value: "IOS",
    },
  ];

  const activateStates = [
    {
      name: "Alle",
      value: "both",
    },
    {
      name: "Aktive",
      value: "true",
    },
    {
      name: "Inaktive",
      value: "false",
    },
  ];

  const customerTypes = [
    {
      name: "Alle",
      value: "",
    },
    {
      name: "Firmenkunde",
      value: "BUSINESS",
    },
    {
      name: "Privatkunde",
      value: "PRIVATE",
    },
  ];

  const userTypes = [
    {
      name: "Alle",
      value: "ALL",
    },
    {
      name: "Anonyme Benutzer",
      value: "ANONYMOUS",
    },
    {
      name: "Registrierte Benutzer",
      value: "NON_ANONYMOUS",
    },
  ];

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    Backend.request(
      "POST",
      "notification",
      JSON.stringify({
        title: subject,
        body: message,
        filter: {
          name: "multi",
          args: notifiableUsers,
        },
        url: url,
      })
    ).then(() => {
      Toast.success(
        "Nachricht senden",
        "Die Nachricht wurde erfolgreich an die Kunden gesendet"
      );
      setSubject("");
      setMessage("");
      setUrl("");
    });
  };

  useEffect(() => {
    Backend.request("GET", "branches")
      .then((result) => result.json())
      .then((result) => {
        result.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
        );
        result = result.map((b) => {
          return { label: b.name, value: b.id };
        });
        result.push({ label: "Keine Filiale", value: 0 });
        setBranches(result);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoading(true);
    var filters = [{ type: "ProfileNotifiableFilter" }];
    if (branchFilter.length > 0) {
      filters.push({
        type: "ProfileBranchFilter",
        parameters: { branches: branchFilter },
      });
    }
    if (isActive === "true") {
      filters.push({ type: "ActiveProfilesFilter" });
    }
    if (isActive === "false") {
      filters.push({ type: "InactiveProfilesFilter" });
    }
    if (platform !== "") {
      filters.push({
        type: "ProfilesByPlatforms",
        parameters: { platforms: [platform] },
      });
    }
    if (zipcode !== "") {
      filters.push({
        type: "ProfileZipcodeFilter",
        parameters: { zipcode: zipcode },
      });
    }
    if (maxRate !== "" && minRate !== "") {
      filters.push({
        type: "ProfilesLastRateFilter",
        parameters: { min: minRate, max: maxRate },
      });
    }
    if (maxCredit !== "" && minCredit !== "") {
      filters.push({
        type: "ProfilesLastCreditFilter",
        parameters: { min: minCredit, max: maxCredit },
      });
    }
    if (minAmountFilter !== "") {
      filters.push({
        type: "ProfilesOverduePaymentAmountFilter",
        parameters: { min: minAmountFilter, max: 999999 },
      });
    }
    if (dueDaysFilter !== "") {
      filters.push({
        type: "ProfilesOverduePaymentSinceDaysFilter",
        parameters: { min: dueDaysFilter, max: 999999 },
      });
    }
    if (customerTypeFilter !== "") {
      filters.push({
        type: "ProfilesTypeFilter",
        parameters: { type: customerTypeFilter },
      });
    }
    if (minEstimatedPriceFilter !== "" && maxEstimatedPriceFilter !== "") {
      filters.push({
        type: "ProfilesContractEstimatedValueFilter",
        parameters: {
          min: minEstimatedPriceFilter,
          max: maxEstimatedPriceFilter,
        },
      });
    }
    if (minDueInDaysFilter !== "" && maxDueInDaysFilter !== "") {
      filters.push({
        type: "ProfilesOverduePaymentInDaysFilter",
        parameters: {
          min: maxDueInDaysFilter * -1,
          max: minDueInDaysFilter * -1,
        },
      });
    }
    if (maxRemainingDurationFilter !== "") {
      filters.push({
        type: "ProfilesContractEndingInDaysFilter",
        parameters: { min: 0, max: maxRemainingDurationFilter },
      });
    }
    if (minExpiredDurationFilter !== "") {
      filters.push({
        type: "ProfilesContractEndingInDaysFilter",
        parameters: { min: minExpiredDurationFilter * -1, max: -1 },
      });
    }
    if (userTypeFilter === "ANONYMOUS") {
      filters.push({
        type: "AnonymousProfilesFilter",
      });
    }
    if (userTypeFilter === "NON_ANONYMOUS") {
      filters.push({
        type: "NotAnonymousProfilesFilter",
      });
    }
    Backend.request(
      "POST",
      "notification/filter",
      JSON.stringify({
        filters: filters,
      })
    )
      .then((result) => result.json())
      .then((result) => {
        setNotifiableUsers(result.filter((item) => item?.userId !== null));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [
    branchFilter,
    isActive,
    maxCredit,
    maxRate,
    minCredit,
    minRate,
    platform,
    zipcode,
    minAmountFilter,
    dueDaysFilter,
    minEstimatedPriceFilter,
    maxEstimatedPriceFilter,
    customerTypeFilter,
    minDueInDaysFilter,
    maxDueInDaysFilter,
    maxRemainingDurationFilter,
    minExpiredDurationFilter,
    userTypeFilter,
  ]);

  return (
    <ApplicationFrame
      title="Gruppennachrichten"
      subtitle="Nachrichten an mehrere App-Benutzer versenden"
      routes={routes}
    >
      <div className="p-6">
        <div className="flex justify-center">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="px-4 sm:px-0 mb-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Nachricht versenden
                    </h3>
                    <p className="mt-1 text-sm leading-5 text-gray-600">
                      Konfigurieren Sie in dieser Eingabemaske die Nachricht,
                      die Sie an eine Gruppe von App-Benutzern senden wollen.
                    </p>
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="company_website"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Titel der Nachricht
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="company_website"
                          name="companyWebsite"
                          ref={register({ required: true, maxLength: 100 })}
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="Willkommen lieber Kunde"
                        />
                      </div>
                      {errors.companyWebsite?.type === "required" && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie einen Titel ein.
                        </div>
                      )}
                      {errors.companyWebsite?.type === "maxLength" && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie einen Titel mit maximal 100 Zeichen
                          ein.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="about"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Nachricht
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="rounded-md shadow-sm">
                      <textarea
                        id="about"
                        onChange={(e) => setMessage(e.target.value)}
                        name="message"
                        ref={register({ required: true, maxLength: 400 })}
                        value={message}
                        rows="3"
                        className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        placeholder="Ihre Nachricht"
                      ></textarea>
                    </div>
                    {errors.message?.type === "required" && (
                      <div className="text-xs text-red-600">
                        Bitte geben Sie eine Nachricht ein.
                      </div>
                    )}
                    {errors.message?.type === "maxLength" && (
                      <div className="text-xs text-red-600">
                        Die Nachricht ist zu lang. Die Nachricht kann nur
                        maximal 400 Zeichen enthalten.
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-3 gap-6 mt-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="landingpage"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Landingpage
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          name="landingpage"
                          ref={register({
                            required: false,
                          })}
                          id="landingpage"
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="https://www.pfando.de/aktion"
                        />
                      </div>
                      {errors.landingpage && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie eine Landingpage ein. Beispielsweise:{" "}
                          <span className="italic">
                            https://pfando.de/aktion/winter2020
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-1 mt-6">
                    <label
                      id="listbox-label"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Filter für die Auswahl der Kunden
                    </label>
                    <div className="relative">
                      <div className="w-full flex flex-col">
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Benutzertyp
                          </p>
                          <Dropdown
                            values={userTypes}
                            onChange={(value) => setUserTypeFilter(value)}
                            value={userTypeFilter}
                          />
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between bg-gray-100 p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Filiale
                          </p>
                          <MultiSelect
                            values={branches}
                            onChange={(value) =>
                              setBranchFilter(value.map((e) => e.value))
                            }
                            defaultValue={branches[0]}
                            placeholder={"Alle Filialen"}
                          />
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Letzte Auszahlung zwischen
                          </p>
                          <div className="flex items-center">
                            <FormattedInput
                              placeholder="10.000,00"
                              onChange={setMinCredit}
                              value={minCredit}
                              faIcon={faEuroSign}
                            />
                            <span className="text-gray-600 text-sm uppercase mx-3">
                              und
                            </span>
                            <FormattedInput
                              placeholder="10.000,00"
                              onChange={setMaxCredit}
                              value={maxCredit}
                              faIcon={faEuroSign}
                            />
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between bg-gray-100 p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Prozentsatz (Miete) des letzten Vertrags zwischen
                          </p>
                          <div className="flex items-center">
                            <FormattedInput
                              placeholder="100"
                              onChange={setMinRate}
                              value={minRate}
                              faIcon={faPercent}
                            />
                            <span className="text-gray-600 text-sm uppercase mx-3">
                              und
                            </span>
                            <FormattedInput
                              placeholder="100"
                              onChange={setMaxRate}
                              value={maxRate}
                              faIcon={faPercent}
                            />
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Schätzwert des letzten Vertrags zwischen
                          </p>
                          <div className="flex items-center">
                            <FormattedInput
                              placeholder="10.000,00"
                              onChange={setMinEstimatedPriceFilter}
                              value={minEstimatedPriceFilter}
                              faIcon={faEuroSign}
                            />
                            <span className="text-gray-600 text-sm uppercase mx-3">
                              und
                            </span>
                            <FormattedInput
                              placeholder="10.000,00"
                              onChange={setMaxEstimatedPriceFilter}
                              value={maxEstimatedPriceFilter}
                              faIcon={faEuroSign}
                            />
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Offene Zahlung fällig seit mehr als
                          </p>
                          <div className="flex items-center">
                            <div className="flex rounded-md shadow-sm">
                              <input
                                className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                                spellCheck="false"
                                placeholder=""
                                type="text"
                                value={dueDaysFilter}
                                onChange={(e) =>
                                  setDueDaysFilter(e.target.value)
                                }
                              />
                              <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                                Tagen
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Offene Zahlung größer als
                          </p>
                          <FormattedInput
                            placeholder="100,00"
                            onChange={setMinAmountFilter}
                            value={minAmountFilter}
                            faIcon={faEuroSign}
                          />
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Offene Forderungen mit Fälligkeit seit
                          </p>
                          <div className="flex rounded-md shadow-sm">
                            <input
                              className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                              spellCheck="false"
                              placeholder=""
                              type="text"
                              value={maxDueInDaysFilter}
                              onChange={(e) =>
                                setMaxDueInDaysFilter(e.target.value)
                              }
                            />
                            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              Tagen
                            </span>
                          </div>
                          <span className="text-gray-600 text-sm uppercase mx-3">
                            und maximal
                          </span>
                          <div className="flex rounded-md shadow-sm">
                            <input
                              className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                              spellCheck="false"
                              placeholder=""
                              type="text"
                              value={minDueInDaysFilter}
                              onChange={(e) =>
                                setMinDueInDaysFilter(e.target.value)
                              }
                            />
                            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              Tagen
                            </span>
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Restlaufzeit des letzten Vertrages kleiner als
                          </p>
                          <div className="flex rounded-md shadow-sm">
                            <input
                              className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                              spellCheck="false"
                              placeholder=""
                              type="text"
                              value={maxRemainingDurationFilter}
                              onChange={(e) =>
                                setMaxRemainingDurationFilter(e.target.value)
                              }
                            />
                            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              Tage
                            </span>
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Laufzeit des letzten Vertrages länger abgelaufen als
                          </p>
                          <div className="flex rounded-md shadow-sm">
                            <input
                              className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                              spellCheck="false"
                              placeholder=""
                              type="text"
                              value={minExpiredDurationFilter}
                              onChange={(e) =>
                                setMinExpiredDurationFilter(e.target.value)
                              }
                            />
                            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                              Tage
                            </span>
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Postleitzahl beginnt mit
                          </p>
                          <div className="flex rounded-md shadow-sm">
                            <input
                              className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
                              spellCheck="false"
                              placeholder="Alle Postleitzahlen"
                              type="text"
                              value={zipcode}
                              onChange={(e) => setZipcode(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Aktiver Kunde
                          </p>
                          <Dropdown
                            values={activateStates}
                            onChange={(value) => setIsActive(value)}
                            value={isActive}
                          />
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Plattform
                          </p>
                          <Dropdown
                            values={platforms}
                            onChange={(value) => setPlatform(value)}
                            value={platform}
                          />
                        </div>
                        <div className="flex mr-3 items-center mt-2 justify-between p-1 bg-gray-100">
                          <p className="text-gray-600 text-sm uppercase mr-3">
                            Kundenart
                          </p>
                          <Dropdown
                            values={customerTypes}
                            onChange={(value) => setCustomerTypeFilter(value)}
                            value={customerTypeFilter}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-between">
                  <CustomerCounter
                    value={notifiableUsers.length}
                    loading={loading}
                  />
                  <button
                    type="button"
                    onClick={loading}
                    className="h-10 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Erreichbare Kunden ermitteln
                  </button>
                  <button
                    disabled={loading}
                    type="submit"
                    className="shadow-sm py-1 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Nachrichten senden
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ApplicationFrame>
  );
};

export default NotificationsPage;
