import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const FormattedInput = ({
  value,
  placeholder,
  faIcon,
  onChange = () => {},
}) => {
  const [current, setCurrent] = useState(
    value !== "" ? Intl.NumberFormat("de-DE").format(value) : value
  );
  const allowOnlyNumbers = (evt) => {
    if (
      !evt.key.match(/[0-9.,]/) &&
      evt.key !== "Backspace" &&
      evt.key !== "ArrowLeft" &&
      evt.key !== "ArrowRight" &&
      evt.key !== "Delete" &&
      evt.key !== "Tab"
    ) {
      evt.preventDefault();
    }
  };
  return (
    <div className="flex rounded-md shadow-sm">
      <input
        className="title bg-white rounded-none rounded-l-md border border-gray-300 p-2 h-10 outline-none"
        spellCheck="false"
        placeholder={placeholder}
        type="text"
        value={current}
        onKeyDown={allowOnlyNumbers}
        onChange={(e) => {
          let value = e.target.value;
          if (value === "") {
            setCurrent(value);
            onChange(value);
            return;
          }
          value = value.replace(/[.]/g, "");
          if (value.match(/^[\d.]+([,]\d+)?$/)) {
            value = value.replace(/,/, ".");
            value = Intl.NumberFormat("de-DE").format(value);
            setCurrent(value);
            onChange(value.replace(/[.]/, "").replace(/,/, "."));
          } else {
            setCurrent(value);
          }
        }}
      />
      <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        <FontAwesomeIcon icon={faIcon} />
      </span>
    </div>
  );
};

export default FormattedInput;
