import React, { useState } from "react";
import Toast from "../ToastProxy";
import Logo from "../logo.svg";
import Backend from "../BackendProxy";

const LoginPage = ({ routes }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = () => {
    Backend.request(
      "POST",
      "login",
      JSON.stringify({
        email: username,
        password: password,
      })
    )
      .then((result) => result.json())
      .then((result) => {
        if (result.error) {
          Toast.error("Login", "Benutzername oder Passwort falsch");
        } else {
          localStorage.setItem("token", result.token);
          window.location = "/";
        }
      })
      .catch((result) => {
        console.error(result);
      });
  };

  return (
    <div className="py-6">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-md">
        <div className="w-full p-8">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">
            <img src={Logo} className="w-1/2" alt="" />
          </h2>
          <p className="text-xl text-gray-600">Pfando App-Center</p>

          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 lg:w-1/4"></span>
            <div className="text-xs text-center text-gray-500 uppercase">
              login mit email adresse
            </div>
            <span className="border-b w-1/5 lg:w-1/4"></span>
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email Addresse
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
              type="email"
              value={username}
              onChange={(evt) => {
                setUsername(evt.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <div className="flex justify-between">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Passwort
              </label>
            </div>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
              type="password"
              value={password}
              onChange={(evt) => {
                setPassword(evt.target.value);
              }}
            />
          </div>
          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
              onClick={() => {
                login();
              }}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
