import React, {useState} from "react";
import {Link} from "react-router-dom";
import Backend from "../BackendProxy";
import Badge from "../components/Badge";
import ApplicationFrame from "../components/ApplicationFrame";
import SearchTable, {SearchTableColumns, SearchTableLoader} from "../components/SearchTable";

const currentDate = new Date();
const startOfMonth = new Date();
startOfMonth.setDate(1);

const datePart = (date: Date) => date.toISOString().substring(0, 10);

const dateFormat = Intl.DateTimeFormat("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
});

interface Notification {
    id: string,
    status: string,
    statusId: 0 | 1 | 2 | 3,
    subject: null | string,
    customerName: null | string,
    body: string,
    createdAt: string,
    readAt: null | string,
    deletedAt: null | string,
}

type NotificationsLoader = (start: string, end: string) => SearchTableLoader<Notification>;
const notificationsLoader: NotificationsLoader = (start, end) => (page, perPage, filterData) => {
    const filters = [];
    filterData["customerName"] && filters.push({column: "customerName", type: "CONTAINS", value: filterData["customerName"]});
    filterData["subject"] && filters.push({column: "title", type: "CONTAINS", value: filterData["subject"]});
    filterData["body"] && filters.push({column: "body", type: "CONTAINS", value: filterData["body"]});

    return Backend.request(
        "POST",
        "notification/list-all",
        JSON.stringify({
            startDate: (start ?? datePart(startOfMonth)) + "T00:00:00",
            endDate: (end ?? datePart(currentDate)) + "T23:59:59",
            page: page,
            perPage: perPage,
            filters: filters,
        })
    )
        .then((result) => result.json())
        .then((result) => ({total: result.count, items: result.content}));
};

const notificationListColumns: SearchTableColumns<Notification> = [
    {
        name: "Status",
        data: (data) => {
            switch (data.statusId) {
                case 0:
                    return <Badge title="Ungelesen" color="rgb(180,0,0)" textColor="white"/>;
                case 1:
                    return <Badge title="Gelesen" color="rgb(0,180,0)" textColor="white"/>;
                case 2:
                    return <Badge title="Gelöscht" color="rgb(247, 213, 47)" textColor="black"/>;
                default:
                    return <Badge title="Ungelesen" color="rgb(180,0,0)" textColor="white"/>;
            }
        },
        filterable: false,
    }, {
        name: "Erstellt am",
        data: (data) => dateFormat.format(new Date(data.createdAt + "Z")),
        filterable: false,
    }, {
        name: "Gelesen am",
        data: (data) => data.readAt ? dateFormat.format(new Date(data.readAt + "Z")) : "nicht gelesen",
        filterable: false,
    }, {
        name: "Gelöscht am",
        data: (data) => data.deletedAt ? dateFormat.format(new Date(data.deletedAt + "Z")) : "nicht gelöscht",
        filterable: false,
    }, {
        name: "Kunde",
        dataName: "customerName",
        filterable: true,
    }, {
        name: "Titel",
        dataName: "subject",
        filterable: true,
    }, {
        name: "Inhalt",
        dataName: "body",
        data: (data) => (data.body.length > 100) ? (data.body.slice(0, 100) + "...") : data.body,
        filterable: true,
    }, {
        name: "Aktionen",
        data: () => "",
        filterable: false,
    },
];

export default function NotificationsPage({routes}: { routes: any }) {
    const [start, setStart] = useState(datePart(startOfMonth));
    const [end, setEnd] = useState(datePart(currentDate));

    const token = localStorage.getItem("token");
    const notificationsEndpoint = `notification/export?startDate=${start}T00:00:00&endDate=${end}T23:59:59&token=${token}`;

    return <ApplicationFrame
        title="Gruppennachrichten"
        subtitle="Nachrichten an mehrere App-Benutzer versenden"
        routes={routes}
        back={null}>
        <div className="bg-white rounded m-4 p-3 flex justify-end">
            <Link to="/notifications/create" className="rounded border border-gray-300 romeis-gradient text-white px-4 py-1 mt-6 mr-4">
                Neue Benachrichtigung
            </Link>
            <a href={Backend.generateUrl(notificationsEndpoint)} download={true}
               className="rounded border border-green-400 bg-green-500 text-white px-4 py-1 mt-6 mr-4">
                Export
            </a>
            <div className="mr-4">
                <label htmlFor="startdate" className="block text-sm font-medium leading-5 text-gray-700">
                    Startdatum
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                        type="date"
                        id="startdate"
                        value={start}
                        onChange={(e) => setStart(start => e.target.value || datePart(startOfMonth))}
                        className="form-input flex-1 block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                </div>
            </div>
            <div className="mr-4">
                <label htmlFor="enddate" className="block text-sm font-medium leading-5 text-gray-700">
                    Endedatum
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                        type="date"
                        id="enddate"
                        value={end}
                        onChange={(e) => setEnd(end => e.target.value || datePart(currentDate))}
                        className="form-input flex-1 block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                </div>
            </div>
        </div>
        <SearchTable loader={notificationsLoader(start, end)}
                     columns={notificationListColumns}
                     rowId={row => row.id}
                     reloadOnChange={[start, end]}/>
    </ApplicationFrame>;
};
