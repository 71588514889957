const Badge = ({ title, color, textColor = "white" }) => {
  return (
    <span
      style={{
        backgroundColor: color,
        borderRadius: "10px",
        padding: "3px 5px",
        fontSize: "0.8rem",
        color: textColor,
      }}
    >
      {title}
    </span>
  );
};

export default Badge;
