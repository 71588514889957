import React, { useEffect, useState } from "react";
import Backend from "../BackendProxy";

const ProfileAuditLog = ({ profileId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  let token = JSON.parse(
    Buffer.from(localStorage.getItem("token").split(".")[1], "base64")
  );

  useEffect(() => {
    Backend.request("GET", "profile/" + profileId + "/audit")
      .then((result) => result.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => console.log(error));
  }, [profileId]);

  const dateFormatOptions = {
    hour12: false,
    timeZone: "Europe/Berlin",
    dateStyle: "medium",
    timeStyle: "medium",
  };

  const rows = data
    .sort((a, b) => {
      return new Date(a.eventDate) < new Date(b.eventDate) ? 1 : -1;
    })
    .map((c) => (
      <tr key={c.id.value}>
        <td className="px-6 py-4 whitespace-no-wrap align-top">
          {new Intl.DateTimeFormat("de", dateFormatOptions).format(
            new Date(c.eventDate)
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap align-top">
          {c.eventType.split(".").at(-1)}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap align-top">
          {JSON.parse(c.userDescriptor).email}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap align-top">
          <ul>
            {Object.entries(JSON.parse(c.eventData)).map(([key, value]) => (
              <li key={key}>
                <strong>{key}</strong>:{" "}
                {typeof value === "object" ? JSON.stringify(value) : value}
              </li>
            ))}
          </ul>
        </td>
      </tr>
    ));

  if (!token.scope.split(" ").includes("ADMIN")) {
    return <div>No acces rights</div>;
  }

  let content = loading ? (
    <div>Daten werden geladen...</div>
  ) : (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Zeitpunkt
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Ereignistyp
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Ausgelöst durch
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default ProfileAuditLog;
