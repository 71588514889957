import React from "react";
import Backend from "../BackendProxy";
import ApplicationFrame from "../components/ApplicationFrame";
import DataWidget from "../components/DataWidget";
import SimpleTable from "../components/SimpleTable";

const AnalyticsPage = ({ routes }) => {
  let startDate = new Date();
  startDate.setDate(1);
  const [start, setStart] = React.useState(startDate.toISOString().substring(0, 10));
  const [end, setEnd] = React.useState(new Date().toISOString().substring(0, 10));

  const [byBranches, setByBranches] = React.useState([]);
  const [byEmployee, setByEmployee] = React.useState([]);
  const [totals, setTotals] = React.useState({
    contracts: 0,
    installations: 0,
    contractsWithInstall: 0,
    quota: 0.0,
  });

  const loadData = React.useCallback((start, end) => {
    Backend
      .request("GET", `analytics/app-installations?start=${start}T00:00:00&end=${end}T23:59:59`)
      .then((result) => result.json())
      .then((result) => {
        setByBranches(result.groupByBranches);
        setByEmployee(result.groupByEmployee);
        setTotals({
          contracts: result.groupByBranches.reduce((a, b) => a + b.contracts, 0),
          installations: result.groupByBranches.reduce((a, b) => a + b.installations, 0),
          contractsWithInstall: result.groupByBranches.reduce((a, b) => a + b.contractsWithInstall, 0),
          quota: Number.parseFloat((result.groupByBranches.reduce((a, b) => a + b.contractsWithInstall, 0) / result.groupByBranches.reduce((a, b) => a + b.contracts, 0)) * 100).toFixed(2) + "%",
        });
      })
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    loadData(start, end);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ApplicationFrame
      title="Analysen"
      subtitle="App Installationen untersuchen"
      routes={routes}
      back={null}
    >
      <div id="wrapper" className="flex justify-between px-4 py-4 mx-auto">
        <div className="sm:grid sm:grid-flow-row sm:gap-4 sm:grid-cols-4">
          <DataWidget title="Neuinstallationen im Zeitraum" value={totals.installations}/>
          <DataWidget title="Abschlüsse im Zeitraum" value={totals.contracts}/>
          <DataWidget title="Abschlüsse mit App im Zeitraum" value={totals.contractsWithInstall}/>
          <DataWidget title="Quote" value={totals.quota}/>
        </div>
        <div className="flex">
          <div className="mr-4">
            <label htmlFor="startdate" className="block text-sm font-medium leading-5 text-gray-700">
              Startdatum
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="date"
                id="startdate"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                className="form-input flex-1 block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="mr-4">
            <label
              htmlFor="enddate"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Endedatum
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="date"
                id="enddate"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                className="form-input flex-1 block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div>
            <button
              className="rounded border border-gray-300 romeis-gradient text-white px-4 py-1 mt-6"
              onClick={() => {
                loadData(start, end);
              }}
            >
              Aktualisieren
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div className="w-1/2 px-4 py-4 mx-4 bg-white border border-gray-300 rounded">
          <SimpleTable
              data={byBranches}
              rowId={stats => stats.name}
              sum={[
                { key: "name", data: (d) => 'Summe' },
                { key: "sum_installations", data: (d) => d.reduce((a, b) => a + b.installations, 0) },
                { key: "sum_contracts", data: (d) => d.reduce((a, b) => a + b.contracts, 0) },
                { key: "sum_contracts_with_app", data: (d) => d.reduce((a, b) => a + b.contractsWithInstall, 0) },
                { key: "sum_quote", data: (d) => (d.reduce((a, b) => a + b.contractsWithInstall, 0)/d.reduce((a, b) => a + b.contracts, 0)*100).toFixed(2)+"%" },
              ]}
              columns={[
                {name: "Filiale", dataName: "name", filterable: true},
                {name: "Neuinstallationen", dataName: "installations", filterable: false},
                {name: "Abschlüsse", dataName: "contracts", filterable: false},
                {name: "Abschlüsse mit App", dataName: "contractsWithInstall", filterable: false},
                {name: "Quote", filterable: false, data: (row) => (row.contracts === 0 || row.contractsWithInstall === 0)
                ? "-"
                : Number.parseFloat((row.contractsWithInstall / row.contracts) * 100).toFixed(2) + "%"},
              ]}
          />
        </div>
        <div className="w-1/2 px-4 py-4 mx-4 bg-white border border-gray-300 rounded">
          <SimpleTable
              data={byEmployee}
              rowId={stats => stats.name}
              sum={[
                { key: "name", data: (d) => 'Summe' },
                { key: "branch", data: (d) => '-' },
                { key: "sum_installations", data: (d) => d.reduce((a, b) => a + b.installations, 0) },
                { key: "sum_contracts", data: (d) => d.reduce((a, b) => a + b.contracts, 0) },
                { key: "sum_contracts_with_app", data: (d) => d.reduce((a, b) => a + b.contractsWithInstall, 0) },
                { key: "sum_quote", data: (d) => (d.reduce((a, b) => a + b.contractsWithInstall, 0)/d.reduce((a, b) => a + b.contracts, 0)*100).toFixed(2)+"%" },
              ]}
              columns={[
                {name: "Mitarbeiter", dataName: "name", filterable: true,},
                {name: "Filiale", dataName: "branch", filterable: true},
                {name: "Neuinstallationen", dataName: "installations", filterable: false},
                {name: "Abschlüsse", dataName: "contracts", filterable: false},
                {name: "Abschlüsse mit App", dataName: "contractsWithInstall", filterable: false},
                {name: "Quote", filterable: false, data: (row) => (row.contracts === 0 || row.contractsWithInstall === 0)
                ? "-"
                : Number.parseFloat((row.contractsWithInstall / row.contracts) * 100).toFixed(2) + "%"},
              ]}
          />
        </div>
      </div>
    </ApplicationFrame>
  );
};

export default AnalyticsPage;
