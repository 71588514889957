import React, { useState } from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Backend from "../BackendProxy";
import { useForm } from "react-hook-form";
import Toast from "../ToastProxy";

const CreateActionPage = ({ routes }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    Backend.request(
      "POST",
      "marketing",
      JSON.stringify({
        name: subject,
        description: message,
        startsAt: Math.floor(Date.parse(start) / 1000),
        endsAt: Math.floor(Date.parse(end) / 1000),
        image: image,
        landingpage: url,
      })
    ).then((result) => result.json()
    ).then((result) => {
      history.push("/marketing/action/" + result.id.value);
      Toast.success(
        "Aktion erstellen",
        "Die Aktion wurde erfolgreich erstellt"
      );
    })
  };


  const history = useHistory();

  return (
    <ApplicationFrame
      title="Marketing"
      subtitle="Eine Marketingaktion erstellen"
      routes={routes}
    >
      <div className="p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Marketingaktion planen
              </h3>
              <p className="mt-1 text-sm leading-5 text-gray-600">
                Konfigurieren Sie in dieser Eingabemaske eine Marketingaktion.
                Im nächsten Schritt können Sie die Empfänger dieser Aktion
                auswählen. Unten sehen Sie eine Vorschau, wie die Aktion in der
                App dargestellt wird.
              </p>
            </div>
            <div className="shadow sm:rounded-md sm:overflow-hidden mt-6">
              <div className="bg-white">
                <img
                  src={
                    image === ""
                      ? "https://dummyimage.com/600x400/cccccc/fff.png"
                      : image
                  }
                  alt="Marketing action title"
                />
                <div className="px-3 pt-4 font-bold">
                  {subject === "" ? "Hier steht der Titel" : subject}
                </div>
                <div className="px-3">
                  {message === "" ? "Hier steht ein Beispieltext" : message}
                </div>
                <div className="flex flex-row-reverse p-5">
                  <a
                    href="#openAction"
                    className="rounded-full bg-green-400 px-6 py-3 text-white font-bold"
                  >
                    Aktion öffnen
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Name der Marketingaktion
                      </label>
                      <div className="mt-1 flex flex-col rounded-md shadow-sm">
                        <input
                          id="title"
                          value={subject}
                          name="promotionname"
                          ref={register({ required: true })}
                          onChange={(e) => setSubject(e.target.value)}
                          className="form-input flex-1 block w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="Willkommen lieber Kunde"
                        />
                        {errors.promotionname && (
                          <div className="text-xs text-red-600">
                            Bitte geben Sie einen Titel ein.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label
                      htmlFor="about"
                      className="block text-sm leading-5 font-medium text-gray-700"
                    >
                      Beschreibung der Marketingaktion
                    </label>
                    <div className="text-xs text-gray-500">
                      Dieser Text erscheint in der App als Teasertext für diese
                      Aktion
                    </div>
                    <div className="rounded-md shadow-sm">
                      <textarea
                        id="about"
                        onChange={(e) => setMessage(e.target.value)}
                        name="description"
                        ref={register({ required: true })}
                        value={message}
                        rows="3"
                        className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        placeholder="Ihre Nachricht"
                      ></textarea>
                      {errors.description && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie eine Beschreibung ein.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="startdate"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Startdatum
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        <input
                          type="date"
                          id="startdate"
                          value={start}
                          onChange={(e) => setStart(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="enddate"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Endedatum
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        <input
                          type="date"
                          id="enddate"
                          value={end}
                          onChange={(e) => setEnd(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="imageurl"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Bild URL
                      </label>
                      <div className="text-xs text-gray-500">
                        Leer lassen wenn Sie kein Bild einbinden möchten
                      </div>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          id="imageurl"
                          value={image}
                          name="imageurl"
                          ref={register({
                            minLength: 0,
                          })}
                          onChange={(e) => setImage(e.target.value)}
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder=""
                        />
                      </div>
                      {errors.imageurl && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie eine gültige URL ein.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        htmlFor="landingpage"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        Landingpage
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          name="landingpage"
                          ref={register({
                            required: true,
                          })}
                          id="landingpage"
                          className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          placeholder="www.pfando.de/aktion"
                        />
                      </div>
                      {errors.landingpage && (
                        <div className="text-xs text-red-600">
                          Bitte geben Sie eine gültige URL ein.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    >
                      Aktion erstellen
                    </button>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ApplicationFrame>
  );
};

export default CreateActionPage;
