import React from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import { Link } from "react-router-dom";
import Backend from "../BackendProxy";
import SearchTable, { FilterValues, SearchTableColumns, SearchTableLoader } from "../components/SearchTable";
import Badge from "../components/Badge";

interface MarketingAction {
  name: string;
  startsAt: number;
  endsAt: number;
  active: boolean;
  id: {value: string};
}

const marketingColumns: SearchTableColumns<MarketingAction> = [
  {
    name: "Status",
    dataName: "active",
    data: (data) => data.active
        ? <Badge title="Aktiv" color="rgb(0,180,0)" />
        : <Badge title="Inaktiv" color="rgb(180,180,180)" />,
    filterable: true,
    possibleValues: ["Aktiv", "Inaktiv"],
}, {
    name: "Name",
    dataName: "name",
    filterable: true,
}, {
  name: "Von",
  data: (action) => {
    const date = new Date(action.startsAt * 1000);
    return <div>{new Intl.DateTimeFormat("de-DE",{ dateStyle: 'medium' }).format(date)}</div>
  },
  filterable: false,
}, {
  name: "Bis",
  data: (action) => {
    const date = new Date(action.endsAt * 1000);
    return <div>{new Intl.DateTimeFormat("de-DE",{ dateStyle: 'medium' }).format(date)}</div>
  },
  filterable: false,
}, {
  name: "Aktionen",
  data: (action) => {
    return <>
    <Link
          to={"/marketing/action/" + action.id.value}
          className="bg-gray-300 hover:bg-gray-400  text-white font-bold py-2 px-4 mr-2 border rounded"
        >
          Bearbeiten
        </Link>
        <Link
          to={"/marketing/action/" + action.id.value + "/assign"}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Empfänger auswählen
        </Link>
    </>
  },
  filterable: false,
},
];

const marketingListLoader: SearchTableLoader<MarketingAction> = (page: number, perPage: number, filterData: FilterValues<MarketingAction>) => {
  const filters: any = [];
  filterData["name"] && filters.push({
    propertyName: "name",
    comparator: "CONTAINS",
    value: filterData["name"],
  });
  filterData["active"] && filters.push({
    propertyName: "active",
    comparator: "EQUALS_BOOL",
    value: filterData["active"].toLowerCase() === "aktiv"
  });
  
  return new Promise((resolve, reject) => {
    Backend.request("POST", "marketing/filtered", JSON.stringify({
        page,
        perPage,
        filters
      }),
    )
      .then((result) => result.json())
      .then((data) => {
        resolve({total: data.content.length, items: data.content});
      })
      .catch((error) => reject(error));
  });
};

const MarketingListPage = ({ routes }: {routes: any}) => {
  return (
    <ApplicationFrame
      title="Marketing"
      subtitle="Aktionen verwalten"
      routes={routes}
      back={null}
    >
      <div className="p-6">
        <div className="flex flex-row mb-4">
          <Link
            to="/marketing/create"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Aktion erstellen
          </Link>
        </div>
        <SearchTable
          loader={marketingListLoader}
          columns={marketingColumns}
          rowId={row => row.id.value}
          reloadOnFilterKeys={["active"]}
        />
      </div>
    </ApplicationFrame>
  );
};

export default MarketingListPage;
