import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Backend from "../BackendProxy";
import Logo from "../logo_white.svg";

const Navbar = ({ title, routes = [] }) => {
  let token = JSON.parse(
    Buffer.from(localStorage.getItem("token").split(".")[1], "base64")
  );
  token.scope.split(" ").includes("ADMIN");
  const [avatar, setAvatar] = React.useState("");
  const username = token.firstName + " " + token.lastName;

  let navRoutes = routes
    .filter((route) => route.inMenu ?? true)
    .map((route) => (
      <div className="flex -mb-px mr-8" key={route.path}>
        <NavLink
          className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
          activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
          exact={route.exact}
          to={route.path}
        >
          {route.title ?? "No title"}
        </NavLink>
      </div>
    ));

  useEffect(() => {
    Backend.request("GET", "profile/avatar")
      .then((result) => result.json())
      .then((result) => {
        setAvatar(result.avatarImage);
      });
  }, []);

  return (
    <>
      <div className="romeis-gradient">
        <div className="mx-auto px-6">
          <div className="flex items-center md:justify-between py-4">
            <Link className="navbar-brand text-white font-bold text-xl" to="/">
              <img src={Logo} className="w-32" alt="" />
            </Link>
            <div className="w-1/4 md:w-auto md:flex text-right">
              <div>
                <img
                  className="inline-block h-8 w-8 rounded-full"
                  src={avatar}
                  alt=""
                />
              </div>
              <div className="hidden md:flex md:items-center ml-2">
                <span className="text-white text-sm mr-1">{username}</span>
                {/*<div>
                  <svg
                    className="fill-current text-white h-4 w-4 block opacity-50"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z" />
                  </svg>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden bg-blue-dark md:block md:bg-white md:border-b">
        <div className="mx-auto px-6">
          <div className="md:flex">
            {navRoutes}
            <div className="flex-grow">&nbsp;</div>
            <div className="flex -mb-px mr-8">
              <a
                className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
                href="#logout"
                onClick={() => {
                  localStorage.removeItem("token");
                }}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
