import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText, faEye } from "@fortawesome/free-solid-svg-icons";
import Backend from "../BackendProxy";
import SearchTable, { SearchTableColumns, SearchTableLoader } from "../components/SearchTable";
import Badge from "../components/Badge";

interface CampaignCustomer {
    id: string,
    userId: string,
    active: boolean,
    hasOpenedCampaign: boolean,
    firstname: null | string,
    lastname: null | string,
    street: null | string,
    zipcode: null | string,
    city: null | string,
    branchName: null | string,
    licensePlates: string,
    lastCredit: number,
    lastEstimated: number,
}

const customerListColumns: SearchTableColumns<CampaignCustomer> = [
    {
        name: "Status",
        dataName: "active",
        data: (data) => data.active
            ? <Badge title="Aktiv" color="rgb(0,180,0)" />
            : <Badge title="Inaktiv" color="rgb(180,180,180)" />,
        filterable: true,
        possibleValues: ["Aktiv", "Inaktiv"],
    }, {
        name: "Lesestatus",
        data: (data) => data.hasOpenedCampaign
            ? <Badge title="Gelesen" color="rgb(0,180,0)" />
            : <Badge title="Ungelesen" color="rgb(180,180,180)" />,
        filterable: false,
    }, {
        name: "Vorname",
        dataName: "firstname",
        filterable: true,
    }, {
        name: "Nachname",
        dataName: "lastname",
        filterable: true,
    }, {
        name: "Straße",
        dataName: "street",
        filterable: true,
    }, {
        name: "PLZ",
        dataName: "zipcode",
        filterable: true,
    }, {
        name: "Ort",
        dataName: "city",
        filterable: true,
    }, {
        name: "Filiale",
        data: (customer) => <div>{customer.branchName}</div>,
        filterable: false,
    }, {
        name: "Kennzeichen",
        dataName: "licensePlates",
        data: (data) => data.licensePlates
            ? Array.from(new Set(data.licensePlates.split(", "))).map((c) => <div>{c}</div>)
            : <></>,
        filterable: true,
    }, {
        name: "Aktionen",
        data: (row) => (row.userId == null)
            ? <div className="text-gray-500">Kein App Nutzer</div>
            : <div>
                <Link to={"/customers/" + row.id + "/positions"}
                    className="text-gray-600 hover:text-indigo-900 border rounded p-2">
                    <FontAwesomeIcon icon={faEye} />
                </Link>
                <Link to={"/notifications/toCustomer/" + row.userId}
                    className="text-indigo-600 hover:text-indigo-900 p-2 border rounded ml-2">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                </Link>
            </div>,
        filterable: false,
    },
];

const customerListLoader = (campaignId: number): SearchTableLoader<CampaignCustomer> => {
    return (page, perPage, filterData) => {
        const filters = [];
        filterData["firstname"] && filters.push({
            propertyName: "profile_firstname",
            comparator: "CONTAINS",
            value: filterData["firstname"],
        });
        filterData["lastname"] && filters.push({
            propertyName: "profile_lastname",
            comparator: "CONTAINS",
            value: filterData["lastname"],
        });
        filterData["street"] && filters.push({
            propertyName: "profile_street",
            comparator: "CONTAINS",
            value: filterData["street"],
        });
        filterData["zipcode"] && filters.push({
            propertyName: "profile_zipcode",
            comparator: "CONTAINS",
            value: filterData["zipcode"],
        });
        filterData["city"] && filters.push({
            propertyName: "profile_city",
            comparator: "CONTAINS",
            value: filterData["city"],
        });
        filterData["branchName"] && filters.push({
            propertyName: "branchName",
            comparator: "CONTAINS",
            value: filterData["branchName"],
        });
        filterData["licensePlates"] && filters.push({
            propertyName: "car_licenseplate",
            comparator: "CONTAINS",
            value: filterData["licensePlates"]
        });
        filterData["active"] && filters.push({
            propertyName: "active",
            comparator: "BETWEEN",
            values: [filterData["active"].toLowerCase() === "aktiv" ? 1 : 0,filterData["active"].toLowerCase() === "aktiv" ? 1 : 0]
        });

        return Backend
            .request("POST", "marketing/campaign/profiles", JSON.stringify({ page, perPage, filters, campaignId }))
            .then((result) => result.json())
            .then(({ count, content }) => {
                if(count !== undefined && content !== undefined) {
                    return {total: count, items: content };
                }
                return {total: 0, items: []};
            });
    }
};

const CampaignCustomerTable = ({ campaignId }: { campaignId: number }) => {
    return <SearchTable loader={customerListLoader(campaignId)} columns={customerListColumns} rowId={row => row.id}
        reloadOnFilterKeys={["active"]} />;
};

export default CampaignCustomerTable;