import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./tailwind.output.css";
import CustomerListPage from "./pages/CustomerListPage";
import CustomerDetailsPage from "./pages/CustomerDetails";
import MarketingListPage from "./pages/MarketingListPage";
import CreateActionPage from "./pages/CreateActionPage";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./pages/LoginPage";
import PromotionDetails from "./pages/PromotionDetails";
import AssignCustomerCampaignPage from "./pages/AssignCustomerCampaignPage";
import SendNotificationToCustomer from "./pages/SendNotificationToCustomer";
import NotificationsPage from "./pages/Notifications";
import NotificationsListPage from "./pages/NotificationsList";
import AnalyticsPage from "./pages/AnalyticsPage";

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <AnalyticsPage routes={routes} />,
    title: "Home",
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/analytics",
    main: () => <AnalyticsPage routes={routes} />,
    title: "Analysen",
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/notifications/create",
    main: () => <NotificationsPage routes={routes} />,
    title: "Gruppenbenachrichtigung",
    exact: true,
    inMenu: false,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/notifications",
    main: () => <NotificationsListPage routes={routes} />,
    title: "Gruppenbenachrichtigungen",
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/marketing",
    main: () => <MarketingListPage routes={routes} />,
    title: "Aktionen",
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/marketing/create",
    main: () => <CreateActionPage routes={routes} />,
    title: "Aktionen",
    exact: true,
    inMenu: false,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/customers/:customerId",
    main: () => <CustomerDetailsPage routes={routes} />,
    title: "Kunden",
    inMenu: false,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/customers",
    main: () => <CustomerListPage routes={routes} />,
    title: "Kunden",
    exact: false,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/marketing/action/:campaignId",
    main: () => <PromotionDetails routes={routes} />,
    title: "Marketingaktion bearbeiten",
    inMenu: false,
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/marketing/action/:campaignId/assign",
    main: () => <AssignCustomerCampaignPage routes={routes} />,
    title: "Marketingaktion bearbeiten",
    inMenu: false,
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
  {
    path: "/notifications/toCustomer/:customerId",
    main: () => <SendNotificationToCustomer routes={routes} />,
    title: "Notification to customer",
    inMenu: false,
    exact: true,
    groups: ["ADMIN", "APP_ANALYST"],
  },
];

const App = () => (
  <Router>
    <ToastContainer transition={Slide} />
    <div className="min-h-screen bg-gray-200">
      <Switch>
        <Route path="/login" exact={true} children={<LoginPage />} />
        {routes.map((route, index) => (
          <ProtectedRoute
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.main />}
            neededGroups={route.groups}
          />
        ))}
      </Switch>
    </div>
  </Router>
);

export default App;
