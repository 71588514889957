import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText, faEye } from "@fortawesome/free-solid-svg-icons";
import Backend from "../BackendProxy";
import ApplicationFrame from "../components/ApplicationFrame";
import SearchTable, { SearchTableColumns, SearchTableLoader } from "../components/SearchTable";
import Badge from "../components/Badge";

const euroFormat = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });

interface Customer {
    id: string,
    userId: string,
    active: boolean,
    name: null | string,
    street: null | string,
    city: null | string,
    branchName: null | string,
    licensePlates: string,
    lastCredit: number,
    lastEstimated: number,
}

const customerListColumns: SearchTableColumns<Customer> = [
    {
        name: "Status",
        dataName: "active",
        data: (data) => data.active
            ? <Badge title="Aktiv" color="rgb(0,180,0)" />
            : <Badge title="Inaktiv" color="rgb(180,180,180)" />,
        filterable: true,
        possibleValues: ["Aktiv", "Inaktiv"],
    }, {
        name: "Name",
        dataName: "name",
        filterable: true,
    }, {
        name: "Straße",
        dataName: "street",
        filterable: true,
    }, {
        name: "Ort",
        dataName: "city",
        filterable: true,
    }, {
        name: "Filiale",
        dataName: "branchName",
        filterable: true,
    }, {
        name: "Kennzeichen",
        dataName: "licensePlates",
        data: (data) => data.licensePlates
            ? Array.from(new Set(data.licensePlates.split(", "))).map((c) => <div>{c}</div>)
            : <></>,
        filterable: true,
    }, {
        name: "Letzte Auszahlung",
        dataName: "lastCredit",
        data: (data) => euroFormat.format(data.lastCredit),
        filterable: true,
    }, {
        name: "Letzter Schätzwert",
        dataName: "lastEstimated",
        data: (data) => euroFormat.format(data.lastEstimated),
        filterable: true,
    }, {
        name: "Aktionen",
        data: (row) => (row.userId == null)
            ? <div className="text-gray-500">Kein App Nutzer</div>
            : <div>
                <Link to={"/customers/" + row.id + "/positions"}
                    className="text-gray-600 hover:text-indigo-900 border rounded p-2">
                    <FontAwesomeIcon icon={faEye} />
                </Link>
                <Link to={"/notifications/toCustomer/" + row.userId}
                    className="text-indigo-600 hover:text-indigo-900 p-2 border rounded ml-2">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                </Link>
            </div>,
        filterable: false,
    },
];

const customerListLoader: SearchTableLoader<Customer> = (page, perPage, filterData) => {
    const filters = [];
    filterData["name"] && filters.push({
        column: "name",
        type: "CONTAINS",
        value: filterData["name"],
    });
    filterData["street"] && filters.push({
        column: "street",
        type: "CONTAINS",
        value: filterData["street"],
    });
    filterData["city"] && filters.push({
        column: "city",
        type: "CONTAINS",
        value: filterData["city"],
    });
    filterData["branchName"] && filters.push({
        column: "branchName",
        type: "CONTAINS",
        value: filterData["branchName"],
    });
    filterData["licensePlates"] && filters.push({
        column: "licensePlates",
        type: "CONTAINS",
        value: filterData["licensePlates"]
    });
    filterData["lastEstimated"] && filters.push({
        column: "lastEstimated",
        type: "BETWEEN",
        values: filterData["lastEstimated"].split("-")
    });
    filterData["lastCredit"] && filters.push({
        column: "lastCredit",
        type: "BETWEEN",
        values: filterData["lastCredit"].split("-")
    });
    filterData["active"] && filters.push({
        column: "active",
        type: "EQUALS_BOOL",
        value: filterData["active"].toLowerCase() === "aktiv"
    });

    return Backend
        .request("POST", "profile/filtered", JSON.stringify({ page, perPage, filters }))
        .then((result) => result.json())
        .then(({ count, content }) => ({ total: count, items: content }));
};

export default function CustomerListPage({ routes }: { routes: any }) {
    return <ApplicationFrame title="Kunden" subtitle="Kundenstamm verwalten" routes={routes} back={null}>
        <div className="sm:px-6 lg:px-8">
            <SearchTable loader={customerListLoader} columns={customerListColumns} rowId={row => row.id}
                reloadOnFilterKeys={["active"]} />
        </div>
    </ApplicationFrame>;
};
