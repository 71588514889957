import React from "react";
import ApplicationFrame from "../components/ApplicationFrame";
import { Switch, useParams, useRouteMatch, useHistory } from "react-router-dom";
import CustomerPositionList from "../components/CustomerPositionList";
import ProfileDetails from "../components/ProfileDetails";
import SendNotificationsForm from "../components/SendNotificationsForm";
import { NavLink } from "react-router-dom";
import UserMessagesList from "../components/UserMessagesList";
import ProfileAuditLog from "../components/ProfileAuditLog";
import ProtectedRoute from "../components/ProtectedRoute";

const CustomerDetailsPage = ({ routes }) => {
  let { customerId } = useParams();
  let { path, url } = useRouteMatch();
  const history = useHistory();

  return (
    <ApplicationFrame
      title="Kundendetails"
      subtitle="Letzte Aufenthaltsorte der App anzeigen"
      routes={routes}
      back={() => history.push("/customers")}
    >
      <div className="px-6 flex bg-white h-16">
        <div className="flex -mb-px mr-8">
          <NavLink
            className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
            activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
            exact={true}
            to={`${url}`}
          >
            Details
          </NavLink>
        </div>
        <div className="flex -mb-px mr-8">
          <NavLink
            className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
            activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
            exact={true}
            to={`${url}/messages`}
          >
            Nachrichten
          </NavLink>
        </div>
        <div className="flex -mb-px mr-8">
          <NavLink
            className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
            activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
            exact={true}
            to={`${url}/new-message`}
          >
            Nachricht senden
          </NavLink>
        </div>
        <div className="flex -mb-px mr-8">
          <NavLink
            className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
            activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
            exact={true}
            to={`${url}/positions`}
          >
            Positionsdaten
          </NavLink>
        </div>
        <div className="flex -mb-px mr-8">
          <NavLink
            className="no-underline opacity-50 text-grey-200 flex items-center py-4 border-b border-transparent hover:opacity-100 hover:border-grey-600"
            activeClassName="no-underline text-green-500 flex items-center py-4 border-b border-green-500 opacity-100"
            exact={true}
            to={`${url}/audit`}
          >
            Aktivitätsprotokoll
          </NavLink>
        </div>
      </div>
      <div className="p-6 flex flex-col">
        <Switch>
          <ProtectedRoute
            path={path}
            exact={true}
            children={<ProfileDetails profileId={customerId} />}
            neededGroups={["ADMIN", "APP_ANALYST"]}
          />
          <ProtectedRoute
            path={`${path}/messages`}
            exact={false}
            children={<UserMessagesList customerId={customerId} />}
            neededGroups={["ADMIN", "APP_ANALYST"]}
          />
          <ProtectedRoute
            path={`${path}/new-message`}
            exact={false}
            children={<SendNotificationsForm profileId={customerId} />}
            neededGroups={["ADMIN", "APP_ANALYST"]}
          />
          <ProtectedRoute
            path={`${path}/positions`}
            exact={false}
            children={<CustomerPositionList customerId={customerId} />}
            neededGroups={["ADMIN"]}
          />
          <ProtectedRoute
            path={`${path}/audit`}
            exact={false}
            children={<ProfileAuditLog profileId={customerId} />}
            neededGroups={["ADMIN"]}
          />
        </Switch>
      </div>
    </ApplicationFrame>
  );
};

export default CustomerDetailsPage;
