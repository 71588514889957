import React, { useEffect, useState } from "react";
import Backend from "../BackendProxy";

const ProfileAttributeWidget = ({ name, value }) => {
  return (
    <div className="flex flex-row border-b border-gray-400 py-2">
      <div className="font-bold mr-4 w-24">{name}</div>
      <div className="">{value}</div>
    </div>
  );
};

const ProfileDetails = ({ profileId }) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  useEffect(() => {
    Backend.request("GET", "profile/" + profileId)
      .then((result) => result.json())
      .then((data) => {
        setProfile(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [profileId]);

  let content = loading ? (
    <p>Lade Profil...</p>
  ) : (
    <>
      <ProfileAttributeWidget name="Vorname" value={profile.firstname} />
      <ProfileAttributeWidget name="Nachname" value={profile.lastname} />
      <ProfileAttributeWidget name="Straße" value={profile.street} />
      <ProfileAttributeWidget name="Postleitzahl" value={profile.zipcode} />
      <ProfileAttributeWidget name="Ort" value={profile.city} />
      <ProfileAttributeWidget name="Telefon" value={profile.phone} />
      <ProfileAttributeWidget name="E-Mail" value={profile.email} />
    </>
  );

  return (
    <div className="flex flex-col bg-white rounded p-4 border">
      <h2 className="font-normal text-gray-600 text-xl">Kundendetails</h2>
      {content}
    </div>
  );
};

export default ProfileDetails;
