import React from "react";
import Select from "react-select";

const MultiSelect = ({ values = [], onChange = (v) => {}, placeholder }) => {
  const [value, setValue] = React.useState([values[0]]);
  return (
    <Select
      options={values}
      isMulti
      value={value}
      onChange={(v) => {
        setValue(v);
        onChange(v);
      }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          minWidth: 200,
        }),
      }}
      placeholder={placeholder}
    />
  );
};

export default MultiSelect;
