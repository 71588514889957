import React from "react";

export type SimpleDataTableProps = {
  data: SimpleTableData[],
  columns: SimpleTableColumn[],
  rowId: (d: SimpleTableData) => string,
  emptyState: string,
  sum: SumColumn[],
};

export type SimpleTableData = {
  id: string,
  [key: string]: any,
  data: (d: SimpleTableData) => string,
}

export type SimpleTableColumn = {
  name: string,
  dataName: string,
  filterable?: boolean,
}

export type ColumnHeaderProps = {
  title: string,
  dataName: string
}

export type DataColumnProps = {
  value: string
}

export type DataRowProps = {
  data: SimpleTableData,
  columns: any[],
}

export type SumRowProps = {
  tableData: SimpleTableData[],
  columns: SumColumn[],
}

export type SumColumn = {
  key: string,
  data: (d: SimpleTableData[]) => string
}

const ColumnHeader = ({ title }: ColumnHeaderProps) => {
  return (
    <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
      <div>{title}</div>
    </th>
  );
};

const DataColumn = ({ value }: DataColumnProps) => {
  return (
    <td className="px-6 py-2 whitespace-no-wrap">
      <div className="flex items-center">
        <div className="leading-5">
          {value}
        </div>
      </div>
    </td>
  );
};

const DataRow = ({ data, columns }: DataRowProps) => {
  return (
    <tr>
      {columns.map((col) => (
        <DataColumn
          value={col.dataName ? data[col.dataName] : col.data(data)}
          key={col.dataName + data.id}
        />
      ))}
    </tr>
  );
};

const SumRow = ({ tableData, columns }: SumRowProps) => {
  if(columns === undefined) {
    return <></>
  }
  console.log(columns.map((col) => col.key));
  return (
    <tr className="bg-gray-50 font-medium text-gray-500 divide-y divide-gray-200 uppercase text-xs tracking-wider">
      {columns.map((col) => (
        <DataColumn
          value={col.data(tableData)}
          key={col.key}
        />
      ))}
    </tr>
  );
};

const DataTable = ({ data, columns, rowId, emptyState = "", sum }: SimpleDataTableProps) => {
  const columnDefinitions = columns.map((c) => {
    return <ColumnHeader title={c.name} dataName={c.dataName} key={c.name} />;
  });

  let content: any[] = [(
    <tr>
      <td colSpan={columns.length}>{emptyState}</td>
    </tr>
  )];
  if (data.length > 0) {
    content = data.map((d) => <DataRow key={rowId?.(d) ?? d} data={d} columns={columns} />);
  }

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>{columnDefinitions}</tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 font-light text-gray-900 text-sm">
        {content}
        <SumRow tableData={data} columns={sum} />
      </tbody>
    </table>
  );
};

export default DataTable;
