import React, { useState } from "react";
import Backend from "../BackendProxy";
import Toast from "../ToastProxy";

import { useForm } from "react-hook-form";

const SendNotificationsForm = ({ profileId }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    Backend.request("GET", "profile/" + profileId)
      .then((result) => result.json())
      .then((profile) => {
        Backend.request(
          "POST",
          "notification",
          JSON.stringify({
            title: subject,
            body: message,
            filter: { name: "single", args: [profile.userId] },
            url: url,
          })
        ).then(() => {
          Toast.success(
            "Nachricht senden",
            "Die Nachricht wurde erfolgreich an den Kunden gesendet"
          );
          setSubject("");
          setMessage("");
          setUrl("");
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="sm:overflow-hidden">
        <div className="px-4 py-5 bg-white sm:p-6">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 sm:col-span-2">
              <label
                htmlFor="company_website"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Titel der Nachricht
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  id="company_website"
                  name="companyWebsite"
                  ref={register({ required: true, maxLength: 100 })}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  placeholder="Willkommen lieber Kunde"
                />
              </div>
              {errors.companyWebsite?.type === "required" && (
                <div className="text-xs text-red-600">
                  Bitte geben Sie einen Titel ein.
                </div>
              )}
              {errors.companyWebsite?.type === "maxLength" && (
                <div className="text-xs text-red-600">
                  Bitte geben Sie einen Titel mit maximal 100 Zeichen ein.
                </div>
              )}
            </div>
          </div>

          <div className="mt-6">
            <label
              htmlFor="about"
              className="block text-sm leading-5 font-medium text-gray-700"
            >
              Nachricht
            </label>
            <div className="rounded-md shadow-sm">
              <textarea
                id="about"
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                ref={register({ required: true, maxLength: 400 })}
                value={message}
                rows="3"
                className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                placeholder="Ihre Nachricht"
              ></textarea>
            </div>
            {errors.message?.type === "required" && (
              <div className="text-xs text-red-600">
                Bitte geben Sie eine Nachricht ein.
              </div>
            )}
            {errors.message?.type === "maxLength" && (
              <div className="text-xs text-red-600">
                Die Nachricht ist zu lang. Die Nachricht kann nur maximal 400
                Zeichen enthalten.
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-6 mt-6">
            <div className="col-span-3 sm:col-span-2">
              <label
                htmlFor="landingpage"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Landingpage
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  name="landingpage"
                  ref={register({
                    required: true,
                  })}
                  id="landingpage"
                  className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  placeholder="www.pfando.de/aktion"
                />
              </div>
              {errors.landingpage && (
                <div className="text-xs text-red-600">
                  Bitte geben Sie eine Landingpage ein.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Nachrichten senden
            </button>
          </span>
        </div>
      </div>
    </form>
  );
};

export default SendNotificationsForm;
